.Slider{
    position: relative;
}
.Slider__image-contanier{
    width: 100%;
    z-index: -1;
}
.Slider__image-contanier-small{
    width: 100%;
    z-index: -1;
    display: none;
}
@media only screen and (max-width: 768px) {
    .Slider__image-contanier-small{
        display: block;
    }
    .Slider__image-contanier{
        display: none;
    }
  }
.Slider__left-button{
    position: absolute;
    left: 1%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 2.5rem;
    cursor: pointer;
}
.Slider__left-button:hover{
    scale: 1.05;
}
.Slider__Right-button{
    position: absolute;
    right: 1%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 2.5rem;
    cursor: pointer;
}
.Slider__Right-button:hover{
    scale: 1.05;
}
.Slider__dot-buttons{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;

}
.Slider__dot-buttons{
 display: flex;
 gap: 1rem;
}
.Slider__inactive{
    font-size: 2rem;
    gap: 5rem;
    color: black;
    padding: 0;
    margin: 0;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    cursor: pointer;
    border: none;
}
.Slider__active{
    font-size: 2rem;
    gap: 5rem;
    color: white;
    padding: 0;
    margin: 0;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    cursor: pointer;
    border: none;
}