.ShowMore{
    position: relative;
    padding-bottom: 1000px;
}
.ShowMore__contanier{
    background-color: #FAF5EF;
    width: 400px;
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 10px 30px;
    border: 2px solid black;
    border-radius: 12px;
    position: absolute;
    left: 50%;
    top: 3vh;
    transform: translateX(-50%);
}
.ShowMore__pic{
    border-radius: 12px;
    max-width: 80vw;
    max-height: 80vw;
}
.ShowMore__btn{
    background-color: black;
    border: none;
    color: #FAF5EF;
    padding: 10px 20px;;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    transform: translateY(+50%);
}
.ShowMore__btn:hover{
    scale: 1.05;
}