.Gallery__image-box {
    display: flex;
    gap: 2vw;
    justify-content: center;
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.Gallery__image {
    width: 20vw;
    height: 20vw;
    cursor: pointer;
}

.Gallery__image:hover {
    scale: 1.02;
}

.Gallery__opened-gallery {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    user-select: none;
    max-width: 100vw;
    max-height: 100vh;
}
.Gallery__opened-img{
    max-width: 80vw;
    max-height: 80vh;
}
.Gallery__exit{
    position: absolute;
    top: 20px;
    right: 30px;
    color: white;
    cursor: pointer;
    transform: translateX(+50%);
}
.Gallery__left-btn{
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    left: 20px;
    color: white;
    cursor: pointer;
    scale: 1.5;
}
.Gallery__right-btn{
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    right: 20px;
    color: white;
    cursor: pointer;
    scale: 1.5;
}
.Gallery__img-number{
    position: absolute;
    color: white;
    right: 30px;
    top: 40px;
    transform: translateX(+50%);
}