.photoZoom__photo-contanier{
    width: 400px;
    height: 400px;
    max-width: 90vw;
    max-height: 90vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.photoZoom__img{
    max-width: 90vw;
    max-height: 90vw;
}
.photoZoom__img:hover{
    transition: transform 0.3s ease;
}